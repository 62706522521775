import request from "../utils/request";
//获取banner列表
export const getbannerlist = data => {
  return request({
    method: 'POST',
    url: '/system/index/getBanners',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}

//新建banner

export const createBanner = data => {
  return request({
    method: 'POST',
    url: '/system/index/createBanner',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//获取页面的对应产品
export const getproductType = data => {
  return request({
    method: 'POST',
    url: '/system/index/chooseForwardContent',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//修改banner
export const changeBanner = data => {
  return request({
    method: 'POST',
    url: '/system/index/modifyBanner',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//获取banner详情
export const getBannerById = data => {
  return request({
    method: 'POST',
    url: '/system/index/bannerDetails',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//删除banner
export const delBanner = data => {
  return request({
    method: 'POST',
    url: '/system/index/deleteBanner',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//状态上线判断
export const justiUp = data => {
  return request({
    method: 'post',
    url: '/system/index/checkOnline',
    data,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // }

  })
}
//批量删除
export const delchos = data => {
  return request({
    method: 'post',
    url: '/system/index/deleteSelect',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}








